import { useMediaQuery } from 'react-responsive';

// Hook will return true value for specific device
/*
{
    "xs": false,
    "sm": false,
    "smPlus": true,
    "md": true,
    "mdPlus": true,
    "lg": true,
    "lgPlus": true,
    "xl": false
}
*/
export default function useDeviceSize() {
  const deviceSizes = {
    smDown: useMediaQuery({ maxWidth: 575 }) || false,
    onlySm: useMediaQuery({ minWidth: 576, maxWidth: 767 }) || false,
    smUp: useMediaQuery({ minWidth: 576 }) || false,
    mdDown: useMediaQuery({ maxWidth: 767 }) || false,
    onlyMd: useMediaQuery({ minWidth: 768, maxWidth: 991 }) || false,
    mdUp: useMediaQuery({ minWidth: 768 }) || false,
    lgDown: useMediaQuery({ maxWidth: 991 }) || false,
    onlyLg: useMediaQuery({ minWidth: 992, maxWidth: 1199 }) || false,
    lgUp: useMediaQuery({ minWidth: 992 }) || false,
    xlDown: useMediaQuery({ maxWidth: 1199 }) || false,
    xlUp: useMediaQuery({ minWidth: 1200 }) || false,
    triggerDesktopNav: useMediaQuery({ minWidth: 930 }) || false,
  };

  return deviceSizes;
}
