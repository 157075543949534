import React from 'react';
import '@ilama007/external-link/dist/index.css';
import { Col, Row } from 'react-flexbox-grid';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_isi.scss`.
 * */

export default function PatientIsi({ isExpanded }) {
  return (
    <section>
      <Row style={{ flexDirection: isExpanded ? 'column' : '' }}>
        <Col lg={isExpanded ? 12 : 6}>
          <p className="isi-h1 pt-0">INDICATION</p>
          <p>
            Vasostrict<sup>&reg;</sup> is indicated to increase blood pressure
            in adults with vasodilatory shock who remain hypotensive despite
            fluids and catecholamines.
          </p>
        </Col>

        <Col lg={isExpanded ? 12 : 6}>
          <p className="isi-h1">
            IMPORTANT SAFETY INFORMATION FOR VASOSTRICT<sup>&reg;</sup>
          </p>
          <p className="isi-h3">CONTRAINDICATIONS</p>
          <p>
            Vasostrict<sup>&reg;</sup> 10 mL multiple dose vial is
            contraindicated in patients with known allergy or hypersensitivity
            to 8-L-arginine vasopressin or chlorobutanol. The 1 mL single dose
            vial and 100 mL pre-mixed single dose vial does not contain
            chlorobutanol and is therefore contraindicated only in patients with
            a known allergy or hypersensitivity to 8-L-arginine vasopressin.
          </p>
          <p className="isi-h3">WARNINGS AND PRECAUTIONS</p>
          <p className="isi-h3">Worsening Cardiac Function</p>
          <p>
            A decrease in cardiac index may be observed with the use of
            vasopressin.
          </p>
          <p className="isi-h3">REVERSIBLE DIABETES INSIPIDUS</p>
          <p>
            Patients may experience reversible diabetes insipidus, manifested by
            the development of polyuria, a dilute urine, and hypernatremia,
            after cessation of treatment with vasopressin. Monitor serum
            electrolytes, fluid status and urine output after vasopressin
            discontinuation. Some patients may require readministration of
            vasopressin or administration of desmopressin to correct fluid and
            electrolyte shifts.
          </p>
          <p className="isi-h3">ADVERSE REACTIONS</p>
          <p>
            The most common adverse reactions include decreased cardiac output,
            bradycardia, tachyarrhythmias, hyponatremia and ischemia (coronary,
            mesenteric, skin, digital).
          </p>
          <p className="isi-h3">DRUG INTERACTIONS</p>
          <p className="isi-h3">Catecholamines</p>
          <p>
            Use with catecholamines is expected to result in an additive effect
            on mean arterial blood pressure and other hemodynamic parameters.
            Hemodynamic monitoring is recommended; adjust the dose of
            vasopressin as needed.
          </p>
          <p className="isi-h3">Indomethacin</p>
          <p>
            Use with indomethacin may prolong the effect of Vasostrict
            <sup>&reg;</sup> on cardiac index and systemic vascular resistance.
            Hemodynamic monitoring is recommended; adjust the dose of
            vasopressin as needed.
          </p>
          <p className="isi-h3">Ganglionic Blocking Agents</p>
          <p>
            Use with ganglionic blocking agents may increase the effect of
            Vasostrict<sup>&reg;</sup> on mean arterial blood pressure.
            Hemodynamic monitoring is recommended; adjust the dose of
            vasopressin as needed.
          </p>
          <p className="isi-h3">Drugs Suspected of Causing SIADH</p>
          <p>
            Use with drugs suspected of causing SIADH (e.g., SSRIs, tricyclic
            antidepressants, haloperidol, chlorpropamide, enalapril, methyldopa,
            pentamidine, vincristine, cyclophosphamide, ifosfamide, felbamate)
            may increase the pressor effect in addition to the antidiuretic
            effect of Vasostrict<sup>&reg;</sup>. Hemodynamic monitoring is
            recommended; adjust the dose of vasopressin as needed.
          </p>
          <p className="isi-h3">
            Drugs Suspected of Causing Diabetes Insipidus
          </p>
          <p>
            Use with drugs suspected of causing diabetes insipidus (e.g.,
            demeclocycline, lithium, foscarnet, clozapine) may decrease the
            pressor effect in addition to the antidiuretic effect of Vasostrict
            <sup>&reg;</sup>. Hemodynamic monitoring is recommended; adjust the
            dose of vasopressin as needed.
          </p>
          <p className="isi-h3">OVERDOSAGE</p>
          <p>
            Overdosage with Vasostrict<sup>&reg;</sup> can be expected to
            manifest as consequences of vasoconstriction of various vascular
            beds (peripheral, mesenteric, and coronary) and as hyponatremia. In
            addition, overdosage may lead less commonly to ventricular
            tachyarrhythmias (including Torsade de Pointes), rhabdomyolysis, and
            non-specific gastrointestinal symptoms.
            <br />
            Direct effects will resolve within minutes of withdrawal of
            treatment.
          </p>
          <p className="bold isi-full-pi">
            Please see the{' '}
            <a
              href="https://www.endodocuments.com/VASOSTRICT/PI"
              rel="noreferrer"
              target="_blank"
            >
              Full Prescribing Information
            </a>{' '}
            for Vasostrict<sup>&reg;</sup>.
          </p>
          <p className="bold isi-full-pi">
            Please see the{' '}
            <a
              href="/pdfs/Vasostrict_Premixed_Solution_-_Technical_Bulletin_3.7.2022.pdf"
              target="_blank"
            >
              Technical Bulletin on Dextrose 5% Information
            </a>{' '}
            for Vasostrict<sup>&reg;</sup>.
          </p>
        </Col>
      </Row>
    </section>
  );
}
