import React, { useContext } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import { Logo } from 'components';
import PropTypes from 'prop-types';
import { appContext } from 'providers/appProvider';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import imgLogo from 'images/xyz-client-logo.png';
import FooterLogoImg from 'images/logo-vasostrict-green.png';
import ParLogoImg from 'images/par-logo.png';
import EndoLogoImg from 'images/logo-endo.png';
import TruLogoImg from 'images/logo-tru.png';

const year = new Date().getFullYear();

const PatientFooter = () => {
  const { appConfigs } = useContext(appContext);

  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <Row className="align-items-center">
          <Col xs={12} md={7} className="col-lg-7_5">
            <div className="container-footer-logo">
              <div className="logo-divider-wrapper desktop-only">
                <Logo
                  image={FooterLogoImg}
                  url="/"
                  className="footer-logo"
                  imageAlt="Vasostrict® logo"
                />
              </div>

              <div className="logo-divider-wrapper">
                <a
                  href="https://parpharm.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="logo footer-logo"
                >
                  <img src={ParLogoImg} alt="Par Pharmaceuticals logo" />
                </a>
              </div>

              <div className="logo-divider-wrapper">
                <a
                  href="https://www.endo.com/products-and-capabilities/focus-areas/#sterile-injectables"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="logo footer-logo"
                >
                  <img src={EndoLogoImg} alt="Endo logo" />
                </a>
              </div>

              <div className="logo-divider-wrapper">
                <a
                  href="https://www.TruDelivery.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="logo footer-logo"
                >
                  <img src={TruLogoImg} alt="TruDelivery logo" />
                </a>
              </div>
            </div>
          </Col>

          <Col xs={12} md={5} className="col-lg-4_5">
            <div className="inner-content">
              <p className="copyright-text">
                <span className="bold">Intended for U.S. Residents Only</span>
                <br />
                ©&nbsp;{year} Endo, Inc. or one of its affiliates. All rights
                reserved.
                <br />
                <span className="bold">
                  <a
                    className="link-privacy"
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.endo.com/privacy-legal"
                  >
                    Privacy/Legal
                  </a>
                  &nbsp; {appConfigs.metaData.PATIENT_JOB_CODE}/July 2024
                  {/* {appConfigs.metaData.CURRENT_YEAR}{' '} */}
                </span>
                <br />
                <span className="links">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.endo.com/privacy-legal"
                  >
                    www.endo.com
                  </a>
                  {' | '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.TruDelivery.com"
                  >
                    www.TruDelivery.com
                  </a>
                  {' | '}
                  <a href="tel:18008289393">
                    <nobr>1-800-828-9393</nobr>
                  </a>
                </span>
                {/* {appConfigs.metaData.CURRENT_YEAR} XYZ Pharma, Inc. All
            rights reserved. <br />
            {appConfigs.metaData.PATIENT_JOB_CODE} */}
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const HcpFooter = () => {
  const { appConfigs } = useContext(appContext);
  return (
    <div className="main-footer-content-wrapper">
      <div className="gutter-all footer-content">
        <div className="inner-content">
          <Logo
            image={imgLogo}
            url="https://www.deerfieldagency.com"
            target="_blank"
            className="client-logo"
          />
          <p className="copyright-text">
            BRANDX<sup>®</sup> is a registered trademark of XYZ Pharma, Inc.{' '}
            <br />© {appConfigs.metaData.CURRENT_YEAR} XYZ Pharma, Inc. All
            rights reserved. <br />
            {appConfigs.metaData.HCP_JOB_CODE} March 2020
          </p>
        </div>
      </div>
    </div>
  );
};

const FooterUtilityNav = ({ className, ...props }) => {
  const { topOffset } = useContext(appContext);

  return (
    <div {...props} className={className}>
      <div className="gutter-all footer-content">
        <Row center="xs">
          <Col xs={12}>
            <div className="container-footer-logo logo-vasostrict mobile-only">
              <Logo
                image={FooterLogoImg}
                url="/"
                className="client-logo inline-block"
                imageAlt="Vasostrict® logo"
              />
            </div>
          </Col>
          <Col xs={12} md={11.5} lg={9} xl={8}>
            <ul className="utility-list">
              <li>
                <Link to="/workflow-scenarios">Workflow Scenarios</Link>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.endodocuments.com/VASOSTRICT/PI"
                >
                  Prescribing Information
                </a>
              </li>
              <li>
                <AnchorLink
                  href="#normal-chappy-isi"
                  className="btn-expand-isi gutter-right"
                  offset={topOffset}
                >
                  Important Safety Information
                </AnchorLink>
              </li>
              <li>
                <a
                  className="ot-sdk-show-settings"
                  style={{ cursor: 'pointer' }}
                >
                  Cookies Settings
                </a>
              </li>
              <li>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.parpharm.com/contact-us/"
                >
                  Contact
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default function Footer({ indication, ...props }) {
  return (
    <footer {...props} className="footer-wrapper">
      <FooterUtilityNav className="footer-utility-nav" />
      {indication === 'hcp' && <HcpFooter />}
      {indication === 'patient' && <PatientFooter />}
    </footer>
  );
}

Footer.propTypes = {
  indication: PropTypes.string.isRequired,
};
