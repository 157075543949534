import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {appContext} from 'providers/appProvider';

/**
 *
 * pageTitle: String (Required)
 * pageDescription: String (Optional)
 * pageKeywords: Array of Strings (Optional)
 * ##Note## Optional values, if not provided, will be used from app.settings.js
 *
 * Example:
 * import { Seo } from "../codes/components";
 *
 * <Seo pageTitle="home" />
 */

export default function Seo({pageTitle, pageDescription, pageKeywords}) {
  const {appConfigs} = useContext(appContext);
  const metaDescription =
    pageDescription || appConfigs.metaData.SITE_DEFAULT_DESCRIPTION;
  const metaKeywords =
    pageKeywords || appConfigs.metaData.SITE_DEFAULT_KEYWORDS;

  return (
    <Helmet
      title={pageTitle}
      titleTemplate={`%s | ${appConfigs.metaData.SITE_NAME}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        metaKeywords.length > 0
          ? {
              name: `keywords`,
              content: metaKeywords.join(`, `),
            }
          : [],
      )}
    />
  );
}

Seo.propTypes = {
  pageDescription: PropTypes.string,
  pageKeywords: PropTypes.arrayOf(PropTypes.string),
  pageTitle: PropTypes.string.isRequired,
};
