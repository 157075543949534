import React from 'react';
import PropTypes from 'prop-types';

export default function FullBleed({ className, children, ...props }) {
  return (
    <div {...props} className={`full-bleed ${className}`}>
      <div className="center-container">
        <div className="gutter-left gutter-right">{children}</div>
      </div>
    </div>
  );
}

FullBleed.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
