import React, { useState, useEffect, useRef, useContext } from 'react';
import Helmet from 'react-helmet';
import { Header, Body, Footer, Isi } from './layout-blocks';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { appContext } from 'providers/appProvider';
import { Modal } from '@ilama007/simple-overlay';
import { checkUrlParamFor } from 'libs/utilityHelper';
import { useCookies } from 'react-cookie';
import '@ilama007/simple-overlay/dist/index.css';
import 'scss/default-theme/theme.scss';

export default function Layout({
  indication,
  children,
  className,
  referencesHtml,
  ...props
}) {
  const {
    updateCurrentIndication,
    updateMagicshot,
    headerHeight,
    appConfigs,
    magicshot,
  } = useContext(appContext);
  const footerRef = useRef(null);
  const [elmRef, setElmRef] = useState(null);
  const [showHcpModal, setShowHcpModal] = useState(false);
  const [cookies, setCookie] = useCookies(['hcp-site-visited']);

  const isHcpCookieSet = () => {
    const cookieFlag = cookies['hcp-site-visited'];
    if (!cookieFlag) {
      setShowHcpModal(true);
    }
    return cookieFlag;
  };

  const isMagicShotAdded = () => {
    const magicFlag = checkUrlParamFor('magicshot');
    // update the context
    magicFlag && updateMagicshot(true);
    return magicFlag;
  };

  const handlePatientClick = () => {
    document.referrer !== '' ? window.history.back() : navigate('/');
    setShowHcpModal(false);
  };

  const handleHcpClick = () => {
    setCookie('hcp-site-visited', 'visited', { path: '/' });
    setShowHcpModal(false);
  };

  useEffect(() => {
    setElmRef(footerRef.current);
    updateCurrentIndication(indication);
    if (!isMagicShotAdded() && indication === 'hcp') {
      isHcpCookieSet();
    }
  }, []);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `page ${indication} ${className}`,
        }}
      />
      <div {...props} className="content-body-wrapper">
        <div className="content-body">
          <Header indication={indication} />
          <Body
            indication={indication}
            style={
              appConfigs.siteOptions.HEADER_TYPE === 'fixed' && !magicshot
                ? { paddingTop: headerHeight }
                : {}
            }
          >
            {children}
          </Body>
          <Modal
            shouldOpen={showHcpModal}
            modalTitle={appConfigs.hcpModalInfo.TITLE}
            modalBody={appConfigs.hcpModalInfo.BODY}
            className={appConfigs.hcpModalInfo.CLASS_NAME}
            buttonOneTitle={appConfigs.hcpModalInfo.BUTTON_ONE_CAPTION}
            buttonTwoTitle={appConfigs.hcpModalInfo.BUTTON_TWO_CAPTION}
            onButtonOneHandler={handlePatientClick}
            onButtonTwoHandler={handleHcpClick}
          >
            <p>Test</p>
          </Modal>
          {elmRef && <Isi footerRef={elmRef} indication={indication} />}
          <div ref={footerRef}>
            <div className="container-references gutter-all">
              {referencesHtml}
            </div>
            <Footer indication={indication} />
          </div>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  indication: PropTypes.oneOf(['hcp', 'patient']).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  referencesHtml: PropTypes.node,
};
