import React, { useContext } from 'react';
import ExternalLink from '@ilama007/external-link';
import { appContext } from 'providers/appProvider';
import '@ilama007/external-link/dist/index.css';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_isi.scss`.
 * */

export default function HcpIsi() {
  const { appConfigs } = useContext(appContext);
  return (
    <section>
      <p className="isi-h1">IMPORTANT SAFETY INFORMATION:</p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <p className="isi-h2">Contraindications:</p>
      <p>None</p>
      <p className="isi-h2">Warnings and Precautions:</p>
      <ul className="regular">
        <li>
          <span>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </span>
        </li>
        <li>
          <span>
            Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse
            quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat
            quo voluptas nulla pariatur?
          </span>
        </li>
        <li>
          <span>
            Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis
            suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur
          </span>
        </li>
        <li>
          <span>
            Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Ut enim ad minima veniam,
            quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi
            ut aliquid ex ea commodi consequatur
          </span>
        </li>
        <li>
          <span>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae
            dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
            aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
            eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
            qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
            sed quia non numquam eius modi tempora incidunt ut labore et dolore
            magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
            nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut
            aliquid ex ea commodi consequatur? Quis autem vel eum iure
            reprehenderit qui in ea voluptate velit esse quam nihil molestiae
            consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla
            pariatur?
          </span>
        </li>
        <li>
          <span>
            At vero eos et accusamus et iusto odio dignissimos ducimus qui
            blanditiis praesentium voluptatum deleniti atque corrupti quos
            dolores et quas molestias excepturi sint occaecati cupiditate non
            provident, similique sunt in culpa qui officia deserunt mollitia
            animi, id est laborum et dolorum fuga.
          </span>
        </li>
      </ul>
      <p className="isi-h2">Adverse Reactions:</p>
      <p>
        Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil
        impedit quo minus id quod maxime placeat facere possimus, omnis voluptas
        assumenda est, omnis dolor repellendus.
      </p>
      <p>
        To report an adverse event, you can visit
        {/**
         * Styles for external link can go into _layout.scss,
         * since it is not user created component.
         * Note:
         * - There is a modal window associated with it which is dynamically created.
         * - Check the _layout.scss to see how to configure those styles and events
         * - Check https://github.com/ilama007/external-link for more customizations
         * */}{' '}
        <ExternalLink
          externalURL="https://www.fda.gov/safety/medwatch"
          messageTitle={appConfigs.externalLinkInfo.TITLE}
          messageBody={appConfigs.externalLinkInfo.BODY}
          classNameLink={appConfigs.externalLinkInfo.LINK_CLASS_NAME}
          classNameOverlay={appConfigs.externalLinkInfo.OVERLAY_CLASS_NAME}
        >
          www.fda.gov/medwatch (This will trigger interstitial message)
        </ExternalLink>{' '}
        or call at 1-800-FDA-XXXX; or you can call XYZ Pharma, Inc. at{' '}
        <nobr>1-XXX-XXX-XXXX</nobr>.
      </p>
      <p className="isi-h2">Indication:</p>
      <p>
        BRANDX<sup>&reg;</sup> Nam libero tempore, cum soluta nobis est eligendi
        optio cumque nihil impedit quo minus id quod maxime placeat facere
        possimus.
      </p>
      <p>
        <a href="http://" target="_blank" rel="noopener noreferrer">
          Please see full Prescribing Information
        </a>
        .
      </p>
      <p>
        Carton with one product: ABC #XXXX-XXX-XX
        <br />
        Carton with two products: EFG #XXXX-XXX-XX
      </p>
    </section>
  );
}
