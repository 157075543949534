/**
 *
 * @param {*} gaObject
 *
 * Example object
 * {
 *  category: "Main Nav",
 *  action: "Click",
 *  label: "About Page"
 * }
 */

const pushEventGA = gaObject => {
  const isGA = typeof window.ga !== 'undefined';
  if (isGA) {
    window.ga(
      'send',
      'event',
      gaObject.category,
      gaObject.action,
      gaObject.label,
    );
  } else {
    console.log('GoogleAnalytics is not live yet!');
    console.log(gaObject);
  }
};

const scrollToElement = (_elementID, _offsetTop) => {
  const el = document.getElementById(_elementID);
  // CanIuse scrollTo support IE 11, Chrome, Safari
  if (el) {
    const topOffset = _offsetTop || 0;
    window.scrollTo(0, el.offsetTop - topOffset);
  }
};

const checkUrlParamFor = _param => {
  const queryStrings =
    typeof window !== 'undefined'
      ? window.location.search.replace('?', '')
      : '';
  const param = _param.toLowerCase().trim();
  return param ? queryStrings.search(param) >= 0 : false;
};

export {pushEventGA, scrollToElement, checkUrlParamFor};
