import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

/**
 * Place all of your styles for this components in
 * `scss/default-theme/components/_nav.scss`.
 * */

export default function HcpNav({ triggerClose }) {
  const handleClick = () => {
    triggerClose && triggerClose();
  };
  return (
    <nav className="main-nav hcp-nav">
      <Link
        to="/hcp"
        className="nav-item"
        activeClassName="active"
        onClick={handleClick}
      >
        Home
      </Link>
      <span className="pipe-separator">|</span>
      <Link
        to="/hcp/clinical-data"
        className="nav-item"
        activeClassName="active"
        onClick={handleClick}
      >
        Clinical Data
      </Link>
      <span className="pipe-separator">|</span>
      <Link
        to="/hcp/clinical-safety-profile"
        className="nav-item"
        activeClassName="active"
        onClick={handleClick}
      >
        Clinical Safety Profile
      </Link>
      <span className="pipe-separator">|</span>
      <Link
        to="/hcp/dosing-and-administration"
        className="nav-item"
        activeClassName="active"
        onClick={handleClick}
      >
        Dosing &amp; Administration
      </Link>
    </nav>
  );
}

/**
 * triggerClose is the methid propagated from the parent Nav component
 * It needs to get triggered sometimes when Link refers to the same page and
 * we need to manually trigger hamburger close
 */

HcpNav.propTypes = {
  triggerClose: PropTypes.func,
};
