import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { appContext } from '../providers/appProvider';

export default function Logo({
  image,
  imageAlt,
  className = '',
  url,
  ...props
}) {
  const { triggerHamburgerClose } = useContext(appContext);

  return (
    <Link
      {...props}
      to={url}
      className={`logo ${className}`}
      onClick={triggerHamburgerClose}
    >
      <img src={image} alt={imageAlt} />
    </Link>
  );
}

Logo.propTypes = {
  image: PropTypes.string.isRequired,
  url: PropTypes.string,
  className: PropTypes.string,
};
